import styled from "@emotion/styled";
import { CheckCircle } from "@mui/icons-material";
import { ListItem, Radio, ListItemText, Divider, Link } from "@mui/material";
import {
  ClearExam,
  ClearQuestions,
  setAction,
  setNumericBar,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuestionBar,
  setQuizQuestionComplete,
  setResetBook,
  setFetchAllSubmittedQuestions,
  updateActiveQuestion,
  setShowResultModeQuiz,
  setShowResult,
  FetchQuestions,
  setShowResultQuiz,
  setRemainTime,
  setTotalExamTime,
  setStudentLevel,
} from "Redux/feature/Questions";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { useNavigate } from "react-router";
import { Box } from "@mui/joy";
import { setResultPage } from "Redux/feature/ChapterExamSlice";
import { getCookie } from "views/common/cookieUtils";
import useTimerSecond from "hooks/useTimerSecond";
import { setActiveIndex } from "Redux/feature/StudentHome";

const StyledItem = styled(ListItem)({
  textAlign: "right",
  marginTop: "0",
  marginBottom: "0",
  paddingTop: "10px",
  paddingBottom: "10px",
  minWidth: "175px",
  "& span": { fontSize: "14px" },
});

interface StepIndicatorProps {
  isActive: boolean;
  isChecked: boolean;
  container_type_id?: any;
  primaryText: React.ReactNode;
}

function StepIndicator({
  isActive,
  isChecked,
  container_type_id,
  primaryText,
}: StepIndicatorProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSerchParams] = useSearchParams();
  const container_id = searchParams.get("container_id");
  const container_type = searchParams.get("container_type_id");
  const assignment_id = searchParams.get("assignment_id");
  const user_id = getCookie("id");



  const resultPage = useAppSelector(
    (state) => state.ChapterExamSlice.resultPage
  );
  const allQuestionAttempt: boolean = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const { isLoading }: any = useAppSelector((state) => state.ChapterExamSlice);
  // const assignment: any = useAppSelector(
  //   (state) => state.StudentsHomeData.assignment
  // );
  const assignmentData: any = localStorage.getItem("assignment");
  const assignment: any = JSON.parse(assignmentData);

  const action = useAppSelector((state) => state.Questions.action);
  const resetAss = useAppSelector((state) => state.Questions.resetAss);
  const isPracticesummaryAndContainer1 =
    (location.pathname.startsWith("/practice-summary") &&
      (searchParams.get("container_type_id") == "2" ||
        searchParams.get("container_type_id") == "6" ||
        searchParams.get("container_type_id") == "1")) ||
    searchParams.get("container_type_id") == "4" || ( (searchParams.get("container_type_id") == "10"||searchParams.get("container_type_id") == "11")&& allQuestionAttempt)||
    searchParams.get("container_type_id") == "5";

  const onResetClick = () => {
    if (user_id) {
      dispatch(
        ClearQuestions({
          user_id: user_id,
          container_id: container_id,
        })
      ).then((res) => {
        dispatch(setQuizQuestionComplete(false));
        dispatch(setFetchAllSubmittedQuestions([]));
        dispatch(updateActiveQuestion(0));
        if (container_type_id == "6") {
          dispatch(setProgressBarFlag(false));
          dispatch(setProgressBarNavigation(false));
        }
        if (container_type_id == "2") {
          dispatch(setProgressBarNavigation(false));
        }
        if (container_type_id == "1") {
          dispatch(setQuestionBar(false));
        }
        if (container_type_id == "7") {
          dispatch(setQuestionBar(false));
        }
        if (container_type_id == "8") {
          dispatch(setQuestionBar(false));
        }
        if (container_type_id == "4") {
          dispatch(setAction(false));
          dispatch(setNumericBar(false));
          dispatch(setResetBook(true));
        }
        const currentIndex = assignment.findIndex((item: any) => {
          return (
            item.types === "container" &&
            item.atomic_container_id == searchParams.get("container_id")
          );
        });
        let currentElement = assignment[currentIndex];
        const examTime = currentElement?.container[0]?.container_duration;
        const containerUrl = `/question-pages?container_type_id=${searchParams.get(
          "container_type_id"
        )}&container_id=${searchParams.get(
          "container_id"
        )}&assignment_id=${searchParams.get("assignment_id")}`;

        const state = { examTime };
        navigate(containerUrl, { state });
      });
    }
  };
  const resetExam = () => {
    if (user_id && isLoading === false) {
      dispatch(
        ClearExam({
          user_id: user_id,
          container_id: container_id,
          assignment_id: assignment_id,
        })
      ).then((res) => {
        dispatch(setQuizQuestionComplete(false));
        dispatch(setResultPage(false));
        dispatch(setNumericBar(false));
        dispatch(setFetchAllSubmittedQuestions([]));
        dispatch(updateActiveQuestion(0));
        if(container_type=="10"||container_type=="11"){
          dispatch(setQuizQuestionComplete(false))
          dispatch(setProgressBarFlag(false));
          dispatch(setProgressBarNavigation(false))
          dispatch(setShowResult(false))
          dispatch(setShowResultModeQuiz(false))
          const indexFirstNotCompleted = assignment?.findIndex(
           (item:any) => item.types == "container" && (item?.container[0]?.container_type_id == "10"||item?.container[0]?.container_type_id == "11")
          );
          console.log(indexFirstNotCompleted,"indexFirstNotCompleted")
          const firstContainer = assignment.find(
            (item:any) => item.types == "container" && (item?.container[0]?.container_type_id == "10"||item?.container[0]?.container_type_id == "11")
          );
        
          const containerUrl = `/question-pages?container_type_id=${firstContainer?.container[0]?.container_type_id}&container_id=${firstContainer?.atomic_container_id}&assignment_id=${firstContainer?.assignment_id}&chapter_type=${firstContainer?.container[0]?.chapter_type}&is_reset=${true}`;
        const examTime = firstContainer?.container[0]?.container_duration;
        dispatch(setActiveIndex(indexFirstNotCompleted))
  
          const state = { examTime };
          navigate(containerUrl, { state });
          dispatch(setQuizQuestionComplete(false))
          dispatch(setShowResultQuiz(false))
         
        }
      });
    }
  };
  return (
    <>
      <StyledItem className="step-indicator-list">
        {isChecked ? (
          <CheckCircle
            sx={{
              color: "#33CD74",
              marginLeft: "5px",
              fontSize: "16px",
              width: "16px",
              height: "16px",
              cursor: "not-allowed",
            }}
          />
        ) : (
          <Radio
            checked={isActive}
            sx={{
              marginLeft: "5px",
              padding: 0,
              color: "#D0D4D9",
              cursor: "not-allowed",
              fontSize: "16px",
              "&.Mui-checked": { color: "#33CD74" },
            }}
          />
        )}
        {/* <ListItemText
          primary={primaryText}
          sx={{ color: isActive ? "#33CD74" : "neutral.main" }}
        /> */}

        <ListItemText
          primary={
            ((container_type_id == 2 ||
              container_type_id == 6 ||
              container_type_id == 1 ||
              (container_type_id == 5 && resultPage) ||  ((container_type_id == 10||container_type_id == 11) && allQuestionAttempt) ||
              (container_type_id == 4 && action)) &&
              isActive &&
              resetAss) ||
            ((container_type_id == 2 ||
              container_type_id == 1 ||
              container_type_id == 6 ||
              (container_type_id == 4 && action) ||  ((container_type_id == 10||container_type_id == 11) && allQuestionAttempt) ||
              (container_type_id == 5 && resultPage)) &&
              isActive) ? (
              <Box sx={{ display: "block" }}>
                <span style={{ display: "flex", justifyContent: "flexStart" }}>
                  {primaryText}
                </span>
                {isPracticesummaryAndContainer1 && (
                  <Link
                    onClick={
                      searchParams.get("container_type_id") == "5" || searchParams.get("container_type_id") == "10"||searchParams.get("container_type_id") == "11"
                        ? resetExam
                        : onResetClick
                    }
                    sx={{
                      textDecoration: "underline",
                      paddingTop: "8px",
                      cursor: "pointer",
                      display: "flex", // Display the Link on a new line
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      איפוס
                    </span>
                  </Link>
                )}
              </Box>
            ) : (
              <span>{primaryText}</span>
            )
          }
          sx={{ color: isActive ? "#33CD74" : "neutral.main" }}
        />
      </StyledItem>
      <Divider
        variant="fullWidth"
        component="li"
        style={{ cursor: "crosshair" }}
      />
    </>
  );
}

export default StepIndicator;
