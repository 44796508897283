import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "api/api";

interface examQuestion {
  user_id: string | null;
  assignment_id: string | number | null | undefined;
}
interface chapterQuestions {
  user_id: string | null;
  assignment_id: string | undefined | null;
  type: string;
  course_id?:string;
  data: any[];
}
interface accordionData {
  user_id: string | undefined | null;
  assignment_id: string;
  type?: string;
}

export const GetChapterExamQuestion = createAsyncThunk(
  "ChapterExamSlice/getChapterExamQuestion",
  async (data: examQuestion) => {
    try {
      const res = await axiosClient.get(
        `get-exam-questions?user_id=${data.user_id}&assignment_id=${data.assignment_id}`
      );
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const SubmitChapterQuestionsAns = createAsyncThunk(
  "ChapterExamSlice/submitChapterQuestionsAns",
  async (data: chapterQuestions) => {
    try {
      const res = await axiosClient.post("submit-question-answer", data);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetChapterExamAccordion = createAsyncThunk(
  "ChapterExamSlice/getChapterExamAccordion",
  async (data: accordionData) => {
    try {
      let queryString = `get-result-exam?user_id=${data.user_id}&assignment_id=${data.assignment_id}`;
      if (data.type) {
        queryString += `&type=${data.type}`;
      }
      const res = await axiosClient.get(queryString);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

export const GetChapterPrincipleAccordion = createAsyncThunk(
  "ChapterExamSlice/getChapterPrincipleAccordion",
  async (data: accordionData) => {
    try {
      let queryString = `get-transversal-principles-result?user_id=${data.user_id}&assignment_id=${data.assignment_id}`;
      if (data.type) {
        queryString += `&type=${data.type}`;
      }
      const res = await axiosClient.get(queryString);
      return res.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  }
);

interface initialStage {
  isLoading: boolean;
  error: any;
  getChapterExamQuestion: any[];
  submitChapterQuestionsAns: any[];
  exam_data: any[];
  assignment_type: number;
  getChapterExamAccordion: {
    parent: {
      id: number;
      name: string;
      total_questions: number;
      total_answer: number;
      total_true_answer: number;
      true_percentage: string;
      questions_data: any[];
    };
    children: any[];
  };
  getChapterPrincipleAccordion: any[];
  selectedChapter: number;
  getPrincipleAccordionNavigationFlag: boolean;
  resultPage: boolean;
  navigatingFromChapterExamF: boolean;
  navigatingFromChapterExamT: boolean;
}

const initialState: initialStage = {
  isLoading: false,
  error: null,
  getChapterExamQuestion: [],
  submitChapterQuestionsAns: [],
  exam_data: [],
  assignment_type: 0,
  getChapterExamAccordion: {
    parent: {
      id: 0,
      name: "",
      total_questions: 0,
      total_answer: 0,
      total_true_answer: 0,
      true_percentage: "",
      questions_data: [],
    },
    children: [],
  },
  getChapterPrincipleAccordion: [],
  selectedChapter: 0,
  getPrincipleAccordionNavigationFlag: true,
  resultPage: false,
  navigatingFromChapterExamF: false,
  navigatingFromChapterExamT: false,
};

const ChapterExamSlice = createSlice({
  name: "ChapterExamSlice",
  initialState,
  reducers: {
    updateSelectedChapter: (state, action) => {
      return { ...state, selectedChapter: action.payload };
    },
    setResultPage: (state, action) => {
      return { ...state, resultPage: action.payload };
    },
    setPrincipleAccordionNavigationFlag: (state, action) => {
      return { ...state, getPrincipleAccordionNavigationFlag: action.payload };
    },
    isNavigatingFromChapterExamF: (state, action) => {
      return { ...state, navigatingFromChapterExamF: action.payload };
    },
    isNavigatingFromChapterExamT: (state, action) => {
      return { ...state, navigatingFromChapterExamT: action.payload };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(GetChapterExamQuestion.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetChapterExamQuestion.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getChapterExamQuestion: action.payload.data,
        };
      })
      .addCase(GetChapterExamQuestion.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: true,
        };
      })
      .addCase(SubmitChapterQuestionsAns.pending, (state, action) => {
        return {
          ...state,
          submitChapterQuestionsAns: [],
          exam_data: [],
          isLoading: true,
        };
      })
      .addCase(SubmitChapterQuestionsAns.fulfilled, (state, action) => {
        return {
          ...state,
          submitChapterQuestionsAns: action.payload.data,
          exam_data: action.payload.exam_details,
          isLoading: false,
        };
      })
      .addCase(SubmitChapterQuestionsAns.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(GetChapterExamAccordion.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetChapterExamAccordion.fulfilled, (state, action) => {
        return {
          ...state,
          getChapterExamAccordion: action.payload.data,
          assignment_type: action.payload.assignment_type,
          isLoading: false,
        };
      })
      .addCase(GetChapterExamAccordion.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(GetChapterPrincipleAccordion.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(GetChapterPrincipleAccordion.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          getChapterPrincipleAccordion: action.payload.data,
          assignment_type: action.payload.assignment_type,
        };
      })
      .addCase(GetChapterPrincipleAccordion.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});

export const {
  updateSelectedChapter,
  setPrincipleAccordionNavigationFlag,
  setResultPage,
  isNavigatingFromChapterExamF,
  isNavigatingFromChapterExamT,
} = ChapterExamSlice.actions;

export default ChapterExamSlice.reducer;
