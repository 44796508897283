import { Button, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  GetAllSubmittedQuestion,
  setProgressBarFlag,
  setProgressBarNavigation,
  setQuestionBar,
  setQuizQuestionComplete,
  updateActiveQuestion,
  setStartIndex,
  FetchQuestions,
} from "Redux/feature/Questions";
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { updateSelectedChapter } from "Redux/feature/ChapterExamSlice";
import { Logout } from "@mui/icons-material";
import { setSideBar } from "Redux/feature/StudentHome";
import { updateQuestionData } from "Redux/feature/onlinePracticeExamSlice";
import { getCookie } from "views/common/cookieUtils";

type Props = {
  containerType: string | null;
};

const ProgressBar = ({ containerType }: Props) => {
  const user_id = getCookie("id");
  const startIndex = useAppSelector((state: any) => state.Questions.startIndex);
  const { questions }: any = useAppSelector((state) => state.Questions);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const chapter_type = searchParams.get("chapter_type")

  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState(false);

  const isDesktop = window.innerWidth > 768;

  const isPracticeSummaryPage = location.pathname === "/practice-summary";

  const isAllQuestionAttempt: any = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );
  const setNumericBar: boolean = useAppSelector(
    (state) => state.Questions.numericBar
  );
  const questionBar: boolean = useAppSelector(
    (state) => state.Questions.questionBar
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const questionData = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.questionData
  );

  const progressBarNavigationFlag = useAppSelector(
    (state) => state.Questions?.progressBarNavigationFlag
  );

  const handleProgressBarClick = (index: number) => {
    if (location.pathname === "/practice-summary") {
      dispatch(setQuizQuestionComplete(true));
      dispatch(setProgressBarNavigation(true));
      const actualIndex = startIndex + index;
      dispatch(updateActiveQuestion(actualIndex));
      dispatch(setQuestionBar(true));
      if (
        searchParams.get("container_type_id") == "7" ||
        searchParams.get("container_type_id") == "8"
      ) {
        dispatch(updateQuestionData(true));
        dispatch(setProgressBarNavigation(true));
        navigate(
          `/question-pages?container_type_id=${searchParams.get(
            "container_type_id"
          )}&container_id=${searchParams.get("container_id")}`,
          {
            state: {
              ...location.state, // Keep the existing state
              isFromTable: false, // Update the isFromTable property
            },
          }
        );
      } else {
        navigate(
          `/question-pages?container_type_id=${searchParams.get(
            "container_type_id"
          )}&container_id=${searchParams.get(
            "container_id"
          )}&assignment_id=${searchParams.get("assignment_id")}`,
          {
            state: {
              ...location.state, // Keep the existing state
              isFromTable: false, // Update the isFromTable property
            },
          }
        );
      }

      dispatch(setQuestionBar(true));
    } else {
      const actualIndex = startIndex + index;
      dispatch(setQuestionBar(true));
      dispatch(setProgressBarNavigation(true));
      dispatch(updateActiveQuestion(actualIndex));
      dispatch(setProgressBarFlag(true));
    }
  };
  const queNav = (index: number, item: any) => {
    dispatch(updateActiveQuestion(index));
  };
  const activeStep = useAppSelector((state) => state.Questions.activeQuestion);
  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const fetchAnswers = isPracticeSummaryPage
    ? fetchAllSubmittedQuestionAnswer
    : questions;

  useEffect(() => {});
  const BoxStyle = {
    minWidth: { xs: "20px", md: "30px" },
    height: { xs: "20px", md: "30px" },
    borderRadius: "3px",
    padding: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
  };
  const handleIncrement = () => {
    if (isDesktop) {
      if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 10) {
        dispatch(
          updateActiveQuestion(fetchAllSubmittedQuestionAnswer.length - 1)
        );
      } else {
        const maxIndex = fetchAllSubmittedQuestionAnswer.length - 10;
        const nextStartIndex = Math.min(startIndex + 10, maxIndex);
        dispatch(setStartIndex(nextStartIndex));
        dispatch(updateActiveQuestion(activeQuestion + 10));
      }
    } else {
      if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 5) {
        dispatch(
          updateActiveQuestion(fetchAllSubmittedQuestionAnswer.length - 1)
        );
      } else {
        const maxIndex = fetchAllSubmittedQuestionAnswer.length - 5;
        const nextStartIndex = Math.min(startIndex + 5, maxIndex);
        dispatch(setStartIndex(nextStartIndex));
        dispatch(updateActiveQuestion(activeQuestion + 5));
      }
    }
  };
  const handleDecrement = () => {
    if (isDesktop) {
      const nextStartIndex = Math.max(startIndex - 10, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 10));
    } else {
      const nextStartIndex = Math.max(startIndex - 5, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 5));
    }
  };
  const handleIncrementBook = () => {
    if (isDesktop) {
      if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 10) {
        dispatch(
          updateActiveQuestion(fetchAllSubmittedQuestionAnswer.length - 1)
        );
      } else {
        const maxIndex = fetchAllSubmittedQuestionAnswer.length - 10;
        const nextStartIndex = Math.min(startIndex + 10, maxIndex);
        dispatch(setStartIndex(nextStartIndex));
        dispatch(updateActiveQuestion(activeQuestion + 10));
      }
    } else {
      const maxIndex = fetchAllSubmittedQuestionAnswer.length - 5;
      const nextStartIndex = Math.min(startIndex + 5, maxIndex);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion + 5));
    }
  };

  const handleDecrementBook = () => {
    if (isDesktop) {
      const nextStartIndex = Math.max(startIndex - 10, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 10));
    } else {
      const nextStartIndex = Math.max(startIndex - 5, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 5));
    }
  };
  const handleIncrementSeries = () => {
    if (isDesktop) {
      if (isAllQuestionAttempt) {
        if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 10) {
          dispatch(
            updateActiveQuestion(fetchAllSubmittedQuestionAnswer.length - 1)
          );
        } else {
          const maxIndex = fetchAllSubmittedQuestionAnswer.length - 10;
          const nextStartIndex = Math.min(startIndex + 10, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 10));
        }
      } else {
        if (activeQuestion >= questions.length - 10) {
          dispatch(updateActiveQuestion(questions.length - 1));
        } else {
          const maxIndex = questions.length - 10;
          const nextStartIndex = Math.min(startIndex + 10, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 10));
        }
      }
    } else {
      if (isAllQuestionAttempt) {
        if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 5) {
          dispatch(
            updateActiveQuestion(fetchAllSubmittedQuestionAnswer.length - 1)
          );
        } else {
          const maxIndex = fetchAllSubmittedQuestionAnswer.length - 5;
          const nextStartIndex = Math.min(startIndex + 5, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 5));
        }
      } else {
        if (activeQuestion >= questions.length - 5) {
          dispatch(updateActiveQuestion(questions.length - 1));
        } else {
          const maxIndex = questions.length - 5;
          const nextStartIndex = Math.min(startIndex + 5, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 5));
        }
      }
    }
  };

  const handleDecrementSeries = () => {
    if (isDesktop) {
      const nextStartIndex = Math.max(startIndex - 10, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 10));
      if (nextStartIndex <= 0) {
        dispatch(updateActiveQuestion(0));
      } else {
        dispatch(updateActiveQuestion(activeQuestion - 10));
      }
    } else {
      const nextStartIndex = Math.max(startIndex - 5, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 5));
    }
  };

  const handleIncrementSingle = () => {
    if (isDesktop) {
      if (isAllQuestionAttempt) {
        if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 10) {
          dispatch(updateActiveQuestion(activeQuestion + 1));
        } else {
          const maxIndex = fetchAllSubmittedQuestionAnswer.length - 10;
          const nextStartIndex = Math.min(startIndex + 10, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(nextStartIndex));
        }
      } else {
        if (activeQuestion >= questions.length - 10) {
          dispatch(updateActiveQuestion(questions.length - 1));
        } else {
          const maxIndex = questions.length - 10;
          const nextStartIndex = Math.min(startIndex + 10, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 10));
        }
      }
    } else {
      if (isAllQuestionAttempt) {
        if (activeQuestion >= fetchAllSubmittedQuestionAnswer.length - 5) {
          dispatch(updateActiveQuestion(activeQuestion + 1));
        } else {
          const maxIndex = fetchAllSubmittedQuestionAnswer.length - 5;
          const nextStartIndex = Math.min(startIndex + 5, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(nextStartIndex));
        }
      } else {
        if (activeQuestion >= questions.length - 5) {
          dispatch(updateActiveQuestion(questions.length - 1));
        } else {
          const maxIndex = questions.length - 5;
          const nextStartIndex = Math.min(startIndex + 5, maxIndex);
          dispatch(setStartIndex(nextStartIndex));
          dispatch(updateActiveQuestion(activeQuestion + 10));
        }
      }
    }
  };

  const handleDecrementSingle = () => {
    if (isDesktop) {
      const nextStartIndex = Math.max(startIndex - 10, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 10));
      if (nextStartIndex <= 0) {
        dispatch(updateActiveQuestion(0));
      } else {
        dispatch(updateActiveQuestion(activeQuestion - 10));
      }
    } else {
      const nextStartIndex = Math.max(startIndex - 5, 0);
      dispatch(setStartIndex(nextStartIndex));
      dispatch(updateActiveQuestion(activeQuestion - 5));
    }
  };

  const selectedChapter: number = useAppSelector(
    (state) => state.ChapterExamSlice.selectedChapter
  );
  const chapterData: any = useAppSelector((state) => state.Questions.chapters);

  const [chapterNumber, setChapterNumber] = useState("");
  const setPrincipleAccordionNavigationFlag: boolean = useAppSelector(
    (state) => state.ChapterExamSlice.getPrincipleAccordionNavigationFlag
  );
  const ChapterExamSlice: any = useAppSelector(
    (state) => state?.ChapterExamSlice?.getChapterExamQuestion
  );
  const container_data: any = useAppSelector(
    (state) => state.Questions.container_data
  );

  const assignmentId = ChapterExamSlice?.assignment?.[0].id;
  const assignment_id = searchParams.get("assignment_id");

  const handleChange = (event: SelectChangeEvent) => {
    if (containerType === "10"||containerType === "11") {
      setSelected(true);
      dispatch(
        FetchQuestions({
          user_id: user_id ? user_id : null,
          container_id: event.target.value,
          assignment_id: assignment_id,
        })
      ).then((res) => {
        dispatch(
          GetAllSubmittedQuestion({
            user_id: user_id ? user_id : null,
            assignment_id: assignment_id,
            container_id: event.target.value,
          })
        );
      });
    }
    containerType === "5" &&
      dispatch(
        GetAllSubmittedQuestion({
          user_id: user_id ? user_id : null,
          assignment_id: assignmentId,
          container_id: event.target.value,
        })
      );
    setOpenDropdown(!openDropdown);
    setChapterNumber(event.target.value);
    dispatch(updateSelectedChapter(event.target.value));
    dispatch(setStartIndex(0));
    dispatch(updateActiveQuestion(0));
  };
  useEffect(() => {
    if (selectedChapter) {
      let stringRepresentation = selectedChapter?.toString();
      setChapterNumber(stringRepresentation);
    } else if ((containerType === "10"||containerType === "11") && chapterData && !selected) {
      setChapterNumber(chapterData[0]?.id);
    }
  }, [selectedChapter, chapterData, selected, containerType === "10",containerType === "11"]);
  useEffect(() => {
    if (setPrincipleAccordionNavigationFlag === true) {
      if (chapterData?.length > 0) {
        setChapterNumber(chapterData[0]?.id);
      } else {
        let stringRepresentation = selectedChapter?.toString();
        setChapterNumber(stringRepresentation);
      }
    } else {
      let stringRepresentation = selectedChapter?.toString();
      setChapterNumber(stringRepresentation);
    }
  }, []);

  useEffect(() => {
    if (containerType === "5") {
      if (setPrincipleAccordionNavigationFlag === false) {
        dispatch(
          GetAllSubmittedQuestion({
            user_id: user_id,
            assignment_id: assignmentId,
            container_id: selectedChapter,
          })
        );
      } else {
        dispatch(updateSelectedChapter(chapterNumber));
      }
    }
  }, [
    chapterNumber,
    setPrincipleAccordionNavigationFlag,
    selectedChapter,
    user_id,
  ]);

  const handleSubmit = () => {
    navigate("/student-home");
    dispatch(setSideBar([]));
  };

  const handleIconClick = () => {
    setOpenDropdown(!openDropdown);
  };
  const pageSize = isDesktop ? 10 : 5;
  const totalQuestions = !isAllQuestionAttempt
    ? questions?.length ?? 0
    : containerType === "1" || containerType === "7" || containerType === "8"
    ? fetchAnswers?.length ?? 0
    : fetchAllSubmittedQuestionAnswer?.length ?? 0;
  const isLastPage = startIndex >= totalQuestions - pageSize;
  const isLastQuestionOfPage = activeQuestion % pageSize === pageSize - 1;
  const isFirstQuestionOfPage = (activeQuestion + 1) % pageSize === 1;

  return (
    <>
      {(containerType === "2" || containerType === "3") && (
        <Box
          className={`${
            activeQuestion == 0
              ? " question-option-3 question-option-mobile-3"
              : ""
          }`}
          sx={{
            display: "contents",
            maxWidth: "305px",
            position: "relative",
            right: "43px",
          }}
        >
          {isDesktop ? (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                        display: questions.length > 10 ? "block" : "none",
                      },
                      pointerEvents: startIndex === 0 ? "none" : "auto",
                      opacity: startIndex === 0 ? 0.5 : 1,
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex + index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity:
                                startIndex + index === activeStep ? 1 : 0.5,
                            }}
                            onClick={() => {
                              queNav(startIndex + index, item);
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents: isLastPage ? "none" : "auto",
                      opacity: isLastPage ? 0.5 : 1,
                      display: questions.length > 10 ? "block" : "none",
                    }}
                    onClick={isLastPage ? undefined : handleIncrementSeries}
                  >
                    <Box
                      className="icon"
                      sx={{
                        pointerEvents: isLastPage ? "none" : "auto",
                        opacity: isLastPage ? 0.5 : 1,
                      }}
                      onClick={isLastPage ? undefined : handleIncrementSeries}
                    ></Box>
                    <Box color={isLastPage ? "gray" : "#7A49B9"}>
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Noto Sans Hebrew",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      color: "#222529",
                      display: {
                        xs: "none",
                        md:
                          fetchAllSubmittedQuestionAnswer.length > 10
                            ? "block"
                            : "none",
                      },
                    }}
                  >
                    <FormattedMessage id="nBBook" />
                  </Typography>

                  <Box
                    className="icon"
                    sx={{
                      marginRight: { xs: "70px", md: "10px" },
                      marginLeft: "5px",
                      pointerEvents: startIndex === 0 ? "none" : "auto",
                      opacity: startIndex === 0 ? 0.5 : 1,
                      display:
                        fetchAllSubmittedQuestionAnswer.length > 10
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {fetchAllSubmittedQuestionAnswer
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            key={startIndex + index}
                            id={`answer${index}`}
                            sx={{
                              ...BoxStyle,

                              border: isPracticeSummaryPage
                                ? "1px solid #AEB5BC"
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? "1px solid #AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "1px solid red"
                                  : "1px solid #33CD75"
                                : "1px solid #AEB5BC",

                              color:
                                startIndex + index == activeStep
                                  ? "grey"
                                  : item.is_skipped_question === 1
                                  ? "#AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#E74C3C"
                                  : "#33CD75",

                              backgroundColor: isPracticeSummaryPage
                                ? ""
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? ""
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#FDF2F1"
                                  : "#F3FCF7"
                                : item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7",
                            }}
                            onClick={() => {
                              handleProgressBarClick(index);
                            }}
                          >
                            {startIndex + index + 1}
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents: isLastPage ? "none" : "auto",
                      opacity: isLastPage ? 0.5 : 1,
                      display:
                        fetchAllSubmittedQuestionAnswer.length > 10
                          ? "block"
                          : "none",
                    }}
                    onClick={isLastPage ? undefined : handleIncrementSeries}
                  >
                    <Box
                      className="icon"
                      sx={{
                        pointerEvents: isLastPage ? "none" : "auto",
                        opacity: isLastPage ? 0.5 : 1,
                      }}
                      onClick={isLastPage ? undefined : handleIncrementSeries}
                    ></Box>
                    <Box color={isLastPage ? "gray" : "#7A49B9"}>
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents: startIndex === 0 ? "none" : "auto",
                      opacity: startIndex === 0 ? 0.5 : 1,
                      visibility: questions.length > 5 ? "visible" : "hidden",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                index + startIndex <= activeStep
                                  ? "1px solid #7A49B9"
                                  : " 1px solid #AEB5BC",
                              color:
                                index + startIndex <= activeStep
                                  ? "#7A49B9"
                                  : "#AEB5BC",
                            }}
                            onClick={() => {
                              queNav(startIndex + index, item);
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "30px",
                      pointerEvents: isLastPage ? "none" : "auto",
                      opacity: isLastPage ? 0.5 : 1,
                      visibility: questions.length > 5 ? "visible" : "hidden",
                    }}
                    onClick={isLastPage ? undefined : handleIncrementSeries}
                  >
                    <Box color={isLastPage ? "gray" : "#7A49B9"}>
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents: startIndex === 0 ? "none" : "auto",
                      opacity: startIndex === 0 ? 0.5 : 1,
                      visibility:
                        fetchAllSubmittedQuestionAnswer.length > 5
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSingle
                    }
                  >
                    <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {fetchAllSubmittedQuestionAnswer
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            key={startIndex + index}
                            id={`answer${index}`}
                            sx={{
                              ...BoxStyle,

                              border: isPracticeSummaryPage
                                ? "1px solid #AEB5BC"
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? "1px solid #AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "1px solid red"
                                  : "1px solid #33CD75"
                                : "1px solid #AEB5BC",

                              color:
                                startIndex + index == activeStep
                                  ? "grey"
                                  : item.is_skipped_question === 1
                                  ? "#AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#E74C3C"
                                  : "#33CD75",

                              backgroundColor: isPracticeSummaryPage
                                ? ""
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? ""
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#FDF2F1"
                                  : "#F3FCF7"
                                : item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7",
                            }}
                            onClick={() => {
                              handleProgressBarClick(index);
                            }}
                          >
                            {startIndex + index + 1}
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents: isLastPage ? "none" : "auto",
                      opacity: isLastPage ? 0.5 : 1,
                      visibility:
                        fetchAllSubmittedQuestionAnswer.length > 5
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={isLastPage ? undefined : handleIncrementSeries}
                  >
                    <Box color={isLastPage ? "gray" : "#7A49B9"}>
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {(containerType === "6" ||
        ((containerType === "10"||containerType === "11") && !isAllQuestionAttempt)) && (
        <Box
          className={`${
            activeQuestion == 0
              ? " question-option-3 question-option-mobile-3"
              : ""
          }`}
          sx={{
            display: "contents",
            maxWidth: "305px",
            position: "relative",
            right: "43px",
          }}
        >
          {isDesktop ? (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      left: 120,
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                      fullWidth
                      startIcon={<Logout sx={{ ml: 2 }} />}
                      onClick={handleSubmit}
                    >
                      <FormattedMessage id="exit" />
                    </Button>
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                        display: "none",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex + index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity:
                                startIndex + index === activeStep ? 1 : 0.5,
                            }}
                            onClick={() => {
                              if (containerType === "6" || ((containerType === "10"||containerType === "11")&&chapter_type=="2")) {
                                queNav(startIndex + index, item);
                              }
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display: "none",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSeries
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Noto Sans Hebrew",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      color: "#222529",
                      display: {
                        xs: "none",
                        md:
                          fetchAllSubmittedQuestionAnswer.length > 10
                            ? "block"
                            : "none",
                      },
                    }}
                  >
                    <FormattedMessage id="nBBook" />
                  </Typography>

                  <Box
                    className="icon"
                    sx={{
                      marginRight: { xs: "70px", md: "10px" },
                      marginLeft: "5px",
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 && progressBarNavigationFlag
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {fetchAllSubmittedQuestionAnswer
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            key={startIndex + index}
                            id={`answer${index}`}
                            sx={{
                              ...BoxStyle,

                              border: isPracticeSummaryPage
                                ? "1px solid #AEB5BC"
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? "1px solid #AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "1px solid red"
                                  : "1px solid #33CD75"
                                : "1px solid #AEB5BC",

                              color:
                                startIndex + index == activeStep
                                  ? "grey"
                                  : item.is_skipped_question === 1
                                  ? "#AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#E74C3C"
                                  : "#33CD75",

                              backgroundColor: isPracticeSummaryPage
                                ? ""
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? ""
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#FDF2F1"
                                  : "#F3FCF7"
                                : item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7",
                            }}
                            onClick={() => {
                              handleProgressBarClick(index);
                            }}
                          >
                            {startIndex + index + 1}
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 && progressBarNavigationFlag
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSeries
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility: "hidden",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSeries
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                index + startIndex <= activeStep
                                  ? "1px solid #7A49B9"
                                  : " 1px solid #AEB5BC",
                              color:
                                index + startIndex <= activeStep
                                  ? "#7A49B9"
                                  : "#AEB5BC",
                            }}
                            onClick={() => {
                              if (containerType === "6" || ((containerType === "10"||containerType === "11")&&chapter_type=="2")) {
                                queNav(startIndex + index, item);
                              }
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "30px",
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      visibility: "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSeries
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 && progressBarNavigationFlag
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {fetchAllSubmittedQuestionAnswer
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            key={startIndex + index}
                            id={`answer${index}`}
                            sx={{
                              ...BoxStyle,

                              border: isPracticeSummaryPage
                                ? "1px solid #AEB5BC"
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? "1px solid #AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "1px solid red"
                                  : "1px solid #33CD75"
                                : "1px solid #AEB5BC",

                              color:
                                startIndex + index == activeStep
                                  ? "grey"
                                  : item.is_skipped_question === 1
                                  ? "#AEB5BC"
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#E74C3C"
                                  : "#33CD75",

                              backgroundColor: isPracticeSummaryPage
                                ? ""
                                : startIndex + index == activeStep
                                ? item.is_skipped_question === 1
                                  ? ""
                                  : item.is_skipped_question === 0 &&
                                    item.is_correct_answer === 0
                                  ? "#FDF2F1"
                                  : "#F3FCF7"
                                : item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7",
                            }}
                            onClick={() => {
                              handleProgressBarClick(index);
                            }}
                          >
                            {startIndex + index + 1}
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 && progressBarNavigationFlag
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSeries
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {containerType === "4" && setNumericBar === true ? (
        <>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Noto Sans Hebrew",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0px",
              textAlign: "center",
              color: "#222529",
              display: { xs: "none", md: "block" },
            }}
          >
            <FormattedMessage id="nBBook" />
          </Typography>
          <Box
            className="icon"
            sx={{
              marginRight: { xs: "70px", md: "10px" },
              marginLeft: "5px",
              pointerEvents: startIndex === 0 ? "none" : "auto",
              opacity: startIndex === 0 ? 0.5 : 1,
            }}
            onClick={startIndex == 0 ? undefined : handleDecrementBook}
          >
            <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
              <ArrowForwardIosIcon />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              cursor: "pointer",
              height: "70px",
              width: { xs: "210px", md: "535px" },
              alignItems: "center",
              position: { xs: "relative", md: "relative" },
              justifyContent: {
                sx: "flex-start",
                md:
                  fetchAllSubmittedQuestionAnswer?.length === 5
                    ? "center"
                    : "flex-start",
              },

              overflowY: { xs: "hidden", md: "auto" },
              overflow: "auto",
              userSelect: "none",
              scrollbarWidth: { xs: "none", md: "none" },
              "&::-webkit-scrollbar": {
                display: { xs: "none", md: "none" },
              },
            }}
          >
            {isDesktop
              ? fetchAllSubmittedQuestionAnswer
                  ?.slice(startIndex, startIndex + 10)
                  .map((item: any, index: any) => {
                    return (
                      <Box
                        key={startIndex + index}
                        id={`answer${index}`}
                        sx={{
                          ...BoxStyle,

                          border: isPracticeSummaryPage
                            ? "1px solid #AEB5BC"
                            : startIndex + index == activeStep
                            ? item.is_skipped_question == 1
                              ? "1px solid #AEB5BC"
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "1px solid red"
                              : "1px solid grey"
                            : "1px solid #AEB5BC",

                          color:
                            startIndex + index == activeStep
                              ? "grey"
                              : item.is_skipped_question == 1
                              ? "#AEB5BC"
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "#E74C3C"
                              : "#33CD75",

                          backgroundColor: isPracticeSummaryPage
                            ? ""
                            : startIndex + index == activeStep
                            ? item.is_skipped_question == 1
                              ? ""
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "#FDF2F1"
                              : "#grey"
                            : item.is_skipped_question == 1
                            ? ""
                            : item.is_skipped_question == 0 &&
                              item.is_correct_answer == 0
                            ? "#FDF2F1"
                            : "#F3FCF7",
                        }}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        {startIndex + index + 1}
                      </Box>
                    );
                  })
              : fetchAllSubmittedQuestionAnswer
                  ?.slice(startIndex, startIndex + 5)
                  .map((item: any, index: any) => {
                    return (
                      <Box
                        key={startIndex + index}
                        id={`answer${index}`}
                        sx={{
                          ...BoxStyle,

                          border: isPracticeSummaryPage
                            ? "1px solid #AEB5BC"
                            : startIndex + index == activeStep
                            ? item.is_skipped_question == 1
                              ? "1px solid #AEB5BC"
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "1px solid red"
                              : "1px solid grey"
                            : "1px solid #AEB5BC",

                          color:
                            startIndex + index == activeStep
                              ? "grey"
                              : item.is_skipped_question == 1
                              ? "#AEB5BC"
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "#E74C3C"
                              : "#33CD75",

                          backgroundColor: isPracticeSummaryPage
                            ? ""
                            : startIndex + index == activeStep
                            ? item.is_skipped_question == 1
                              ? ""
                              : item.is_skipped_question == 0 &&
                                item.is_correct_answer == 0
                              ? "#FDF2F1"
                              : "#grey"
                            : item.is_skipped_question == 1
                            ? ""
                            : item.is_skipped_question == 0 &&
                              item.is_correct_answer == 0
                            ? "#FDF2F1"
                            : "#F3FCF7",
                        }}
                        onClick={() => handleProgressBarClick(index)}
                      >
                        {startIndex + index + 1}
                      </Box>
                    );
                  })}
          </Box>

          <Box
            className="icon"
            sx={{
              pointerEvents:
                startIndex === fetchAllSubmittedQuestionAnswer?.length - 10
                  ? "none"
                  : "auto",
              opacity:
                startIndex === fetchAllSubmittedQuestionAnswer?.length - 10
                  ? 0.5
                  : 1,
            }}
            onClick={
              startIndex >= fetchAllSubmittedQuestionAnswer?.length - 10
                ? undefined
                : handleIncrementBook
            }
          >
            <Box
              color={
                startIndex === fetchAllSubmittedQuestionAnswer?.length - 10
                  ? "gray"
                  : "#7A49B9"
              }
            >
              <ArrowBackIosIcon />
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}

      {(containerType === "5" && setNumericBar === true) ||
      ((containerType === "10"||containerType === "11") && isAllQuestionAttempt) ? (
        <>
          <Box
            className={`${
              activeQuestion == 0
                ? "question-option-3 question-option-mobile-3"
                : " "
            }`}
            sx={{
              display: "contents",
              maxWidth: "305px",
              position: "relative",
              right: "43px",
            }}
          >
            <Box
              sx={{
                height: "24px",
                display: "flex",
                flexDirection: "row-reverse",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: { xs: "20px", md: "20px" },
                marginTop: { xs: "135px", md: "0px" },
                left: 0,
                position: { xs: "absolute", md: "unset" },
                zIndex: { xs: "999" },
              }}
              onClick={handleIconClick}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={chapterNumber}
                  onChange={handleChange}
                  open={openDropdown}
                  onClose={() => setOpenDropdown(false)}
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    height: "40px",
                    width: { xs: "120px", md: "100%" },
                  }}
                  IconComponent={() => (
                    <Box
                      marginTop={"5px"}
                      marginLeft={"5px"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="#7A49B9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Box>
                  )}
                >
                  {chapterData?.map((value: any, index: number) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              פרק:
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontFamily: "Noto Sans Hebrew",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "22px",
                letterSpacing: "0px",
                textAlign: "center",
                color: "#222529",
                display: { xs: "none", md: "block" },
              }}
            >
              <FormattedMessage id="nBBook" />
            </Typography>
            <Box
              className="icon"
              sx={{
                marginLeft: "5px",
                marginRight: {
                  xs: "85px",
                  md: "10px",
                },
                pointerEvents: startIndex === 0 ? "none" : "auto",
                opacity: startIndex === 0 ? 0.5 : 1,
              }}
              onClick={startIndex == 0 ? undefined : handleDecrement}
            >
              <Box color={startIndex === 0 ? "gray" : "#7A49B9"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
                height: "70px",
                width: { xs: "210px", sm: "300", md: "535px" },
                alignItems: "center",
                position: { xs: "relative", md: "relative" },
                overflow: "auto",
                userSelect: "none",
                scrollbarWidth: { xs: "none", md: "none" },
                "&::-webkit-scrollbar": {
                  display: { xs: "none", md: "none" },
                },
              }}
            >
              {isDesktop
                ? fetchAllSubmittedQuestionAnswer
                    ?.slice(startIndex, startIndex + 10)
                    .map((item: any, index: any) => {
                      return (
                        <Box
                          key={startIndex + index}
                          id={`answer${index}`}
                          sx={{
                            ...BoxStyle,

                            border: isPracticeSummaryPage
                              ? "1px solid #AEB5BC"
                              : startIndex + index == activeStep
                              ? item.is_skipped_question === 1
                                ? "1px solid #AEB5BC"
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "1px solid red"
                                : "1px solid #33CD75"
                              : "1px solid #AEB5BC",

                            color:
                              item.is_skipped_question === 1
                                ? "#AEB5BC"
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#E74C3C"
                                : "#33CD75",

                            backgroundColor: isPracticeSummaryPage
                              ? ""
                              : startIndex + index == activeStep
                              ? item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7"
                              : item.is_skipped_question === 1
                              ? ""
                              : item.is_skipped_question === 0 &&
                                item.is_correct_answer === 0
                              ? "#FDF2F1"
                              : "#F3FCF7",
                          }}
                          onClick={() => handleProgressBarClick(index)}
                        >
                          {startIndex + index + 1}
                        </Box>
                      );
                    })
                : fetchAllSubmittedQuestionAnswer
                    ?.slice(startIndex, startIndex + 5)
                    .map((item: any, index: any) => {
                      return (
                        <Box
                          key={startIndex + index}
                          id={`answer${index}`}
                          sx={{
                            ...BoxStyle,

                            border: isPracticeSummaryPage
                              ? "1px solid #AEB5BC"
                              : startIndex + index == activeStep
                              ? item.is_skipped_question === 1
                                ? "1px solid #AEB5BC"
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "1px solid red"
                                : "1px solid #33CD75"
                              : "1px solid #AEB5BC",

                            color:
                              startIndex + index == activeStep
                                ? "grey"
                                : item.is_skipped_question === 1
                                ? "#AEB5BC"
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#E74C3C"
                                : "#33CD75",

                            backgroundColor: isPracticeSummaryPage
                              ? ""
                              : startIndex + index == activeStep
                              ? item.is_skipped_question === 1
                                ? ""
                                : item.is_skipped_question === 0 &&
                                  item.is_correct_answer === 0
                                ? "#FDF2F1"
                                : "#F3FCF7"
                              : item.is_skipped_question === 1
                              ? ""
                              : item.is_skipped_question === 0 &&
                                item.is_correct_answer === 0
                              ? "#FDF2F1"
                              : "#F3FCF7",
                          }}
                          onClick={() => handleProgressBarClick(index)}
                        >
                          {startIndex + index + 1}
                        </Box>
                      );
                    })}
            </Box>

            <Box
              className="icon"
              sx={{
                marginLeft: "30px",
                pointerEvents: isLastPage ? "none" : "auto",
                opacity: isLastPage ? 0.5 : 1,
              }}
              onClick={isLastPage ? undefined : handleIncrement}
            >
              <Box color={isLastPage ? "gray" : "#7A49B9"}>
                <ArrowBackIosIcon />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}

      {containerType === "1" && (
        <Box
          className={`${
            activeQuestion == 0
              ? " question-option-3 question-option-mobile-3"
              : ""
          }`}
          sx={{
            display: "contents",
            maxWidth: "305px",
            position: "relative",
            right: "43px",
          }}
        >
          {isDesktop ? (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                        display: "none",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || isFirstQuestionOfPage ? 0.5 : 1,
                    }}
                    onClick={
                      startIndex === 0 || isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",

                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex+index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity:
                                startIndex + index === activeStep ? 1 : 0.5,
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display: "none",
                    }}
                    onClick={isLastPage ? undefined : handleIncrementSingle}
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Noto Sans Hebrew",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      color: "#222529",
                      display: {
                        xs: "none",
                        md: fetchAnswers.length > 10 ? "block" : "none",
                      },
                    }}
                  >
                    <FormattedMessage id="nBBook" />
                  </Typography>

                  <Box
                    className="icon"
                    sx={{
                      marginRight: { xs: "70px", md: "10px" },
                      marginLeft: "5px",
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || isFirstQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 && progressBarNavigationFlag
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      startIndex == 0 ? undefined : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {location.pathname === "/practice-summary" &&
                    containerType === "1" ? (
                      <>
                        {fetchAnswers
                          ?.slice(startIndex, startIndex + 10)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",
                                  backgroundColor: isPracticeSummaryPage
                                    ? ""
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "#FDF2F1"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#F3FCF7"
                                    : "",
                                  border: isPracticeSummaryPage
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "1px solid #E74C3C"
                                    : startIndex + index === activeStep &&
                                      item.is_skipped_question === 1
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep
                                    ? "1px solid #33CD75"
                                    : "1px solid #AEB5BC",
                                  color: isPracticeSummaryPage
                                    ? item?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : item?.is_skipped_question === 1
                                      ? "#AEB5BC"
                                      : "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 0
                                    ? "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#33CD75"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 1 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0
                                    ? "#AEB5BC"
                                    : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                {startIndex + index + 1}
                              </Box>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {questions
                          ?.slice(startIndex, startIndex + 10)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",

                                  backgroundColor:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      .is_skipped_question === 0
                                      ? "#FDF2F1"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 1
                                      ? "#F3FCF7"
                                      : "",
                                  border:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_skipped_question === 0
                                      ? "1px solid #E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_skipped_question === 1
                                      ? "1px solid #AEB5BC"
                                      : startIndex + index === activeStep
                                      ? "1px solid #AEB5BC"
                                      : "1px solid #AEB5BC",
                                  color:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_skipped_question === 0
                                      ? "#E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_skipped_question === 1 &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 0
                                      ? "#AEB5BC"
                                      : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    )}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 && progressBarNavigationFlag
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility: "hidden",
                    }}
                    onClick={
                      startIndex === 0 || isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex + index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity: index === activeStep ? 1 : 0.5,
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "30px",
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      visibility: "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 && progressBarNavigationFlag
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      startIndex === 0 || !isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {location.pathname === "/practice-summary" &&
                    containerType === "1" ? (
                      <>
                        {fetchAnswers
                          ?.slice(startIndex, startIndex + 5)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",
                                  backgroundColor: isPracticeSummaryPage
                                    ? ""
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "#FDF2F1"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#F3FCF7"
                                    : "",
                                  border: isPracticeSummaryPage
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "1px solid #E74C3C"
                                    : startIndex + index === activeStep &&
                                      item.is_skipped_question === 1
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep
                                    ? "1px solid #33CD75"
                                    : "1px solid #AEB5BC",
                                  color: isPracticeSummaryPage
                                    ? item?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : item?.is_skipped_question === 1
                                      ? "#AEB5BC"
                                      : "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 0
                                    ? "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#33CD75"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 1 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0
                                    ? "#AEB5BC"
                                    : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {questions
                          ?.slice(startIndex, startIndex + 5)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    index <= activeStep ? "pointer" : "default",

                                  backgroundColor:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      .is_skipped_question === 0
                                      ? "#FDF2F1"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 1
                                      ? "#F3FCF7"
                                      : "",
                                  border:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_skipped_question === 0
                                      ? "1px solid #E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_skipped_question === 1
                                      ? "1px solid #AEB5BC"
                                      : startIndex + index === activeStep
                                      ? "1px solid #AEB5BC"
                                      : "1px solid #AEB5BC",
                                  color:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_skipped_question === 0
                                      ? "#E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_skipped_question === 1 &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 0
                                      ? "#AEB5BC"
                                      : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    )}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 && progressBarNavigationFlag
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      {(containerType === "7" || containerType === "8") && (
        <Box
          className={`${
            activeQuestion == 0
              ? " question-option-3 question-option-mobile-3"
              : ""
          }`}
          sx={{
            display: "contents",
            maxWidth: "305px",
            position: "relative",
            right: "43px",
          }}
        >
          {isDesktop ? (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                        display: "none",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                    }}
                    onClick={
                      startIndex === 0 || !isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 10)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex + index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity:
                                startIndex + index === activeStep ? 1 : 0.5,
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display: "none",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Noto Sans Hebrew",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      color: "#222529",
                      display: {
                        xs: "none",
                        md: fetchAnswers.length > 10 ? "block" : "none",
                      },
                    }}
                  >
                    <FormattedMessage id="nBBook" />
                  </Typography>

                  <Box
                    className="icon"
                    sx={{
                      marginRight: { xs: "70px", md: "10px" },
                      marginLeft: "5px",
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 &&
                        progressBarNavigationFlag &&
                        questionData
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      startIndex === 0 || !isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",

                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {(location.pathname === "/practice-summary" &&
                      containerType === "7") ||
                    containerType === "8" ? (
                      <>
                        {fetchAnswers
                          ?.slice(startIndex, startIndex + 10)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",
                                  backgroundColor: isPracticeSummaryPage
                                    ? ""
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "#FDF2F1"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#F3FCF7"
                                    : "",
                                  border: isPracticeSummaryPage
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "1px solid #E74C3C"
                                    : startIndex + index === activeStep &&
                                      item.is_skipped_question === 1
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep
                                    ? "1px solid #33CD75"
                                    : "1px solid #AEB5BC",
                                  color: isPracticeSummaryPage
                                    ? item?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : item?.is_skipped_question === 1
                                      ? "#AEB5BC"
                                      : "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 0
                                    ? "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#33CD75"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 1 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0
                                    ? "#AEB5BC"
                                    : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                {startIndex + index + 1}
                              </Box>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {questions
                          ?.slice(startIndex, startIndex + 10)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                              sx={{
                                ...BoxStyle,
                                cursor:
                                  startIndex + index <= activeStep
                                    ? "pointer"
                                    : "default",

                                backgroundColor:
                                  startIndex + index === activeStep &&
                                  fetchAllSubmittedQuestionAnswer[activeStep]
                                    ?.is_correct_answer === 0 &&
                                  fetchAllSubmittedQuestionAnswer[activeStep]
                                    .is_skipped_question === 0
                                    ? "#FDF2F1"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#F3FCF7"
                                    : "",
                                    border:
                                    startIndex + index < activeStep
                                      ? "1px solid #ba93ed"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0 &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 0
                                      ? "1px solid #E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 1
                                      ? "1px solid #535b61"
                                      : "1px solid #AEB5BC",
                                    color:
                                     startIndex + index < activeStep
                                      ? "#ba93ed"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0 &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 0
                                      ? "#E74C3C"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 1 &&
                                        fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0
                                      ? "#535b61"
                                      : "#AEB5BC",
                              }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    )}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      display:
                        fetchAnswers.length > 10 &&
                        progressBarNavigationFlag &&
                        questionData
                          ? "block"
                          : "none",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              {!isAllQuestionAttempt ? (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility: "hidden",
                    }}
                    onClick={
                      startIndex === 0 || !isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {questions
                      ?.slice(startIndex, startIndex + 5)
                      .map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{
                              ...BoxStyle,
                              border:
                                startIndex + index <= activeStep
                                  ? "1px solid #7A49B9"
                                  : startIndex + index < activeStep
                                  ? "1px solid #7A49B9"
                                  : "1px solid #AEB5BC",
                              color:
                                startIndex + index === activeStep
                                  ? "#7A49B9"
                                  : startIndex + index < activeStep
                                  ? "#7A49B9"
                                  : "#4A5056",
                              opacity: index === activeStep ? 1 : 0.5,
                            }}
                          >
                            <span> {startIndex + index + 1}</span>
                          </Box>
                        );
                      })}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "30px",
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,

                      visibility: "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    className="icon"
                    sx={{
                      marginLeft: "5px",
                      marginRight: {
                        xs: "85px",
                        md: "10px",
                      },
                      pointerEvents:
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "none"
                          : "auto",
                      opacity:
                        startIndex === 0 || !isFirstQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 &&
                        !progressBarNavigationFlag &&
                        questionData
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      startIndex === 0 || !isFirstQuestionOfPage
                        ? undefined
                        : handleDecrementSingle
                    }
                  >
                    <Box
                      color={
                        startIndex === 0 || !isFirstQuestionOfPage
                          ? "gray"
                          : "#7A49B9"
                      }
                    >
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                      height: "70px",
                      justifyContent: "center",
                      width: { xs: "210px", sm: "300", md: "535px" },
                      alignItems: "center",
                      position: { xs: "relative", md: "relative" },
                      overflow: "auto",
                      userSelect: "none",
                      scrollbarWidth: { xs: "none", md: "none" },
                      "&::-webkit-scrollbar": {
                        display: { xs: "none", md: "none" },
                      },
                    }}
                  >
                    {(location.pathname === "/practice-summary" &&
                      containerType === "7") ||
                    containerType === "8" ? (
                      <>
                        {fetchAnswers
                          ?.slice(startIndex, startIndex + 5)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",
                                  backgroundColor: isPracticeSummaryPage
                                    ? ""
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "#FDF2F1"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#F3FCF7"
                                    : "",
                                  border: isPracticeSummaryPage
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      item.is_skipped_question === 0
                                    ? "1px solid #E74C3C"
                                    : startIndex + index === activeStep &&
                                      item.is_skipped_question === 1
                                    ? "1px solid #AEB5BC"
                                    : startIndex + index === activeStep
                                    ? "1px solid #33CD75"
                                    : "1px solid #AEB5BC",
                                  color: isPracticeSummaryPage
                                    ? item?.is_correct_answer === 1
                                      ? "#33CD75"
                                      : item?.is_skipped_question === 1
                                      ? "#AEB5BC"
                                      : "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 0
                                    ? "#E74C3C"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 1
                                    ? "#33CD75"
                                    : startIndex + index === activeStep &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_skipped_question === 1 &&
                                      fetchAllSubmittedQuestionAnswer[
                                        activeStep
                                      ]?.is_correct_answer === 0
                                    ? "#AEB5BC"
                                    : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {questions
                          ?.slice(startIndex, startIndex + 5)
                          .map((item: any, index: any) => {
                            return (
                              <Box
                                sx={{
                                  ...BoxStyle,
                                  cursor:
                                    startIndex + index <= activeStep
                                      ? "pointer"
                                      : "default",

                                  backgroundColor:
                                    startIndex + index === activeStep &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      ?.is_correct_answer === 0 &&
                                    fetchAllSubmittedQuestionAnswer[activeStep]
                                      .is_skipped_question === 0
                                      ? "#FDF2F1"
                                      : startIndex + index === activeStep &&
                                        fetchAllSubmittedQuestionAnswer[
                                          activeStep
                                        ]?.is_correct_answer === 1
                                      ? "#F3FCF7"
                                      : "",
                                      border:
                                      startIndex + index < activeStep
                                        ? "1px solid #ba93ed"
                                        : startIndex + index === activeStep &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0 &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 0
                                        ? "1px solid #E74C3C"
                                        : startIndex + index === activeStep &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 1
                                        ? "1px solid #535b61"
                                        : "1px solid #AEB5BC",
                                      color:
                                       startIndex + index < activeStep
                                        ? "#ba93ed"
                                        : startIndex + index === activeStep &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0 &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 0
                                        ? "#E74C3C"
                                        : startIndex + index === activeStep &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 1
                                        ? "#33CD75"
                                        : startIndex + index === activeStep &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_skipped_question === 1 &&
                                          fetchAllSubmittedQuestionAnswer[activeStep]?.is_correct_answer === 0
                                        ? "#535b61"
                                        : "#AEB5BC",
                                }}
                                onClick={
                                  questionBar
                                    ? () => handleProgressBarClick(index)
                                    : undefined
                                }
                              >
                                <span> {startIndex + index + 1}</span>
                              </Box>
                            );
                          })}
                      </>
                    )}
                  </Box>

                  <Box
                    className="icon"
                    sx={{
                      pointerEvents:
                        isLastPage || !isLastQuestionOfPage ? "none" : "auto",
                      opacity: isLastPage || !isLastQuestionOfPage ? 0.5 : 1,
                      visibility:
                        fetchAnswers.length > 5 &&
                        !progressBarNavigationFlag &&
                        questionData
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      isLastPage || !isLastQuestionOfPage
                        ? undefined
                        : handleIncrementSingle
                    }
                  >
                    <Box
                      color={
                        isLastPage || !isLastQuestionOfPage ? "gray" : "#7A49B9"
                      }
                    >
                      <ArrowBackIosIcon />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ProgressBar;
